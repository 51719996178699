import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import BenefitsSection from 'components/organisms/BenefitsSection';
import CtaSection from 'components/organisms/CtaSection';
import FrequentlyAskedQuestionsSection from 'components/organisms/FrequentlyAskedQuestionsSection';
import PricingTableHeroSection from 'components/organisms/PricingTableHeroSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import ThreeStepsSection from 'components/organisms/ThreeStepsSection';

const PricingPageContent = ({
  pricingTable,
  testimonial,
  benefit,
  faq,
  howItWorksSection,
  ctaSection,
}) => {
  return (
    <>
      <PricingTableHeroSection
        title={pricingTable?.title}
        products={[pricingTable.product1, pricingTable.product2].map(
          (product) => ({
            name: product?.name,
            description: product?.description,
            price: product?.price,
            features: product?.features,
          }),
        )}
        features={pricingTable?.features}
      />
      <TestimonialSection
        quote={testimonial?.quote}
        author={testimonial?.author}
        company={testimonial?.company}
        inverted
        className="bg-aqua mt-0 md:mt-0"
      />
      <BenefitsSection>
        <Benefit
          title={benefit?.title}
          body={benefit?.body}
          image={benefit?.image}
          imageSide={benefit?.imageSide}
          cta={{ label: benefit?.cta?.label, to: benefit?.cta?.url }}
        />
      </BenefitsSection>
      <FrequentlyAskedQuestionsSection
        title={faq?.title}
        body={faq?.body}
        cta={faq?.cta}
        questions={faq?.questions.map(({ heading, body }) => ({
          title: heading,
          body,
        }))}
      />
      <div className="border-b border-border-grey" />
      <ThreeStepsSection
        lead={howItWorksSection?.lead}
        title={howItWorksSection?.title}
        steps={howItWorksSection?.steps.map((step) => ({
          title: step?.title,
          description: step?.body,
        }))}
      />
      <CtaSection headline={ctaSection?.heading} />
    </>
  );
};

export const PricingPreview = ({ entry }) => {
  return <PricingPageContent {...entry.get('data').toJS()} />;
};

const PricingPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout headerBackground="bg-tile">
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <PricingPageContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PricingPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/pricing/" }) {
      frontmatter {
        pricingTable {
          title
          product1 {
            name
            description
            price
            features {
              feature
            }
          }
          product2 {
            name
            description
            price
            features {
              feature
            }
          }
          features {
            feature
          }
        }
        testimonial {
          quote
          author
          company
        }
        benefit {
          title
          body
          imageSide
          image
          cta {
            label
            url
          }
        }
        faq {
          title
          body
          cta {
            label
            url
          }
          questions {
            heading
            body
          }
        }
        howItWorksSection {
          title
          lead
          steps {
            title
            body
          }
        }
        ctaSection {
          heading
        }
        seo {
          title
          description
          openGraphImage
        }
      }
    }
  }
`;

export default PricingPage;
