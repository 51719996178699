import { Link } from 'gatsby';
import React from 'react';

interface TestimonialSectionProps {
  quote: string;
  author?: string;
  company?: string;
  ctaLabel?: string;
  inverted?: boolean;
  className?: string;
  hasRectangle?: boolean;
}

const TestimonialSection: React.FC<TestimonialSectionProps> = ({
  quote,
  author,
  company,
  ctaLabel,
  inverted,
  hasRectangle,
  className,
}) => {
  return (
    <div className={`my-20 md:my-32 ${className}`}>
      <div
        className={`container max-w-screen-xl px-6 mx-auto text-center md:px-20 lg:px-40 xl:px-52 ${
          inverted
            ? 'text-white pt-8 lg:pt-32 pb-12 lg:pb-28'
            : 'text-dark-grey'
        }`}
      >
        {!inverted && (
          <div className="w-32 h-1 mx-auto mb-12 md:mb-20 bg-mid-grey opacity-10" />
        )}

        <p className="mx-auto my-8 text-3xl tracking-tightest font-semibold md:text-4xl lg:text-5xl md:my-11 leading-tighter">
          {quote}
        </p>
        {author && (
          <div className="mb-1 text-lg font-bold lg:text-xl">{author}</div>
        )}
        {company && <div className="mb-6 md:mb-16 text-md lg:text-lg">{company}</div>}
        {/* {ctaLabel && (
          <Link
            className={`font-semibold border-b border-opacity-20 pb-1.5 ${
              inverted ? 'border-white' : 'border-black'
            } text-md lg:text-lg transition hover:text-primary focus:text-primary`}
            to="/"
          >
            {ctaLabel}
          </Link>
        )} */}

        {!inverted && (
          <div className="w-32 h-1 mx-auto mt-16 md:mt-28 bg-mid-grey opacity-10" />
        )}
      </div>
    </div>
  );
};

export default TestimonialSection;
