import React from 'react';
import Accordion from 'components/molecules/Accordion';

interface FrequentlyAskedQuestionsSectionProps {
  title: string;
  body: string;
  cta: {
    label: string;
    url: string;
  };
  questions: {
    title: string;
    body: string;
  }[];
}

const FrequentlyAskedQuestionsSection: React.FC<FrequentlyAskedQuestionsSectionProps> = ({
  title,
  body,
  cta,
  questions,
}) => {
  return (
    <div className="container max-w-screen-xl mx-auto my-20 md:my-32 flex flex-col items-center divide-dark-grey">
      <div className="text-center mb-12 md:mb-24 px-6 md:px-0">
        <h2 className="u-h2 mb-4">{title}</h2>
        <p className="mb-8 mx-auto md:w-110 max-w-full">{body}</p>
        <a className="u-cta-link" href={cta.url} target="_blank">
          {cta.label}
        </a>
      </div>
      <Accordion panels={questions} />
    </div>
  );
};

export default FrequentlyAskedQuestionsSection;
