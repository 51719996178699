import React from 'react';

interface CheckProps {
  color?: string;
  className?: string;
}

const Check: React.FC<CheckProps> = ({ color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      fill="none"
      viewBox="0 0 15 11"
      className={className}
    >
      <path
        className={`fill-current ${color}`}
        d="M5.494 10.94L.904 6.35l1.59-1.591 3 3 7.5-7.5 1.59 1.59-9.09 9.091z"
      ></path>
    </svg>
  );
};

export default Check;
