import React from 'react';
import AccordionPanel from 'components/atoms/AccordionPanel';

interface AccordionPanelModel {
  title: string;
  body: string;
}

interface AccordionProps {
  panels: AccordionPanelModel[];
}

const Accordion: React.FC<AccordionProps> = ({ panels }) => {
  return (
    <div className="md:w-hero space-y-3.5 px-6 md:px-0">
      {panels.map((panel, index) => (
        <AccordionPanel key={index} {...panel} />
      ))}
    </div>
  );
};

export default Accordion;
