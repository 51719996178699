import React from 'react';
import Check from 'components/atoms/Check';
import PricingBox from 'components/molecules/PricingBox';

interface PricingFeatures {
  feature: string;
}

interface PricingTableHeroSectionProps {
  title: string;
  products: {
    name: string;
    description: string;
    price: string;
    features: PricingFeatures[];
  }[];
  features: PricingFeatures[];
}

const PricingTableHeroSection: React.FC<PricingTableHeroSectionProps> = ({
  title,
  products,
  features,
}) => {
  return (
    <div className="pt-32 md:pt-56 pb-20 md:pb-48 bg-tile ">
      <div className="container max-w-screen-xl mx-auto px-6 xl:px-0">
        <h1 className="w-150 max-w-full u-h1 text-center mx-auto mb-16">
          {title}
        </h1>
        <div className="md:bg-white md:border-2 border-black border-opacity-10 w-220 mx-auto max-w-full">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-6/12 flex-shrink-0 md:border-r border-black border-opacity-10 mb-8 md:mb-0 bg-white">
              <div className="border-t-8 border-violet-alt px-6 md:px-11 py-10 md:py-12">
                <PricingBox
                  title={products[0].name}
                  description={products[0].description}
                  price={products[0].price}
                  cta={{
                    url: 'https://go.skupos.com/signup',
                    label: 'Get started',
                  }}
                />

                <ul className="mt-10 space-y-2 text-sm md:text-md">
                  {products[0].features.map(({ feature }, index ) => (
                    <li key={index} className="flex items-center">
                      <Check color="text-violet" className="mr-3.5" /> {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="lg:w-6/12 flex-shrink-0 md:border-l border-black border-opacity-10 bg-white">
              <div className="border-t-8 border-aqua px-6 md:px-11 py-10 md:py-12">
                <PricingBox
                  title={products[1].name}
                  description={products[1].description}
                  price={products[1].price}
                  cta={{
                    url: 'https://go.skupos.com/signup',
                    label: 'Get started',
                  }}
                />

                <ul className="mt-10 space-y-2 text-sm md:text-md">
                  {products[1].features.map(({ feature }, index ) => (
                    <li key={index} className="flex items-center space-x-3.5">
                      <Check color="text-aqua" />
                      <div>{feature}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="p-6 md:text-center border-t-2 border-black border-opacity-10 bg-white">
            <ul className="flex flex-col md:flex-row md:items-center md:justify-center space-y-2 md:space-y-0 md:space-x-9">
              {features.map(({ feature }, index ) => (
                <li
                  key={index}
                  className="flex items-center space-x-3.5 text-sm md:text-md"
                >
                  <Check color="text-aqua" /> <div>{feature}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTableHeroSection;
