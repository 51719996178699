import { Link } from 'gatsby';
import React from 'react';
import CtaButton from 'components/atoms/CtaButton';

interface PricingBoxProps {
  title: string;
  description: string;
  price: string;
  cta: { url: string; label: string };
}

const PricingBox: React.FC<PricingBoxProps> = ({
  title,
  description,
  price,
  cta,
}) => {
  return (
    <div className="md:pr-28">
      <div className="flex items-end md:h-20">
        <h3 className="mb-4 text-3xl font-bold tracking-tight lg:text-4xl">
          {title}
        </h3>
      </div>
      <p className="mb-6 lg:mb-11">{description}</p>
      <div className="mb-2 text-4xl font-bold">{price}</div>
      <div className="mb-8">Per store / month</div>
      <CtaButton
        to={cta.url}
        label={cta.label}
        inverted
        className="md:px-12 w-full md:w-auto"
      />
    </div>
  );
};

export default PricingBox;
